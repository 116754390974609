$ma-base-color: #ecf4fb;
$ma-dark-color: #51667c;
$ma-accent-color: #3297FF;
$ma-border-color: #007bff;
$ma-cookie-consent-color: #130f40;

$am-base-color: #fbecec;
$am-dark-color: #7c5153;
$am-accent-color: #dd3b5e;
$am-border-color: #c42345;
$am-cookie-consent-color: #130f40;

$afghan-base-color: #FFF;
$afghan-dark-color: #F0750C;
$afghan-accent-color: #0384da;
$afghan-border-color: #FFF;
$afghan-cookie-consent-color: #130f40;

$iddrs-base-color: #f1f1f1;
$iddrs-dark-color: #637A86;
$iddrs-accent-color: #E64415;
$iddrs-border-color: #c42345;
$iddrs-cookie-consent-color: #130f40;

$lbnmas-base-color: #f63146;
$lbnmas-dark-color: #39391e;
$lbnmas-accent-color: #f63146;
$lbnmas-border-color: #f63146;
$lbnmas-cookie-consent-color: #130f40;



.mineaction {
    header {
      &.masthead {
          background-image: url('img/mineaction_bg_1.jpg');
      }
    }

    background-color: $ma-base-color;
    & .controls{
        background-color: $ma-dark-color;
        background-image:url('img/mineaction_bg_1.jpg');
        background-size:cover;
        background-position: center;
    }
    & .item_count, .btn-primary {
        background-color: $ma-accent-color;
    }
    & .btn-primary:hover{
        background-color: $ma-border-color;
    }
    & .btn-primary{
        border-color: $ma-border-color;
    }
    &.fill-bg {
      main {
        > div {
          > .container {
            background-color: white;
          }
        }
      }
    }
    .badge-informative {
      background-color: #666;
      color:#fff;
    }
    .toast {
      .toast-header,
      .toast-body {
        background-color: $ma-accent-color;
        color:white;
        .text-muted  {
          color:white;
        }
        .close {
          color:white;
        }
      }
    }


    .cookie-consent{
      background-color:$ma-cookie-consent-color;
    }

    .loader {
      span {
        &:nth-child(1){
          background-color: #FFFFFF;
        }

        &:nth-child(2){
          background-color: #3297FF;
        }

        &:nth-child(3){
          background-color: #FFFFFF;
        }
      }
    }
    .link-success {
        color: #198754;
    }
}

.ammunitionmanagement {
    header {
      &.masthead {
          background-image: url('img/ammunitionmanagement_bg_1.jpg');
      }
    }
    background-color:$am-base-color;
    & .controls{
        background-color: $am-dark-color;
        background: url('img/ammunitionmanagement_bg_1.jpg');
        background-size: cover;
        background-position: center;
    }
    & .item_count, .btn-primary {
        background-color: $am-accent-color;
    }
    & .btn-primary:hover{
        background-color: $am-border-color;
    }
    & .btn-primary{
        border-color: $am-border-color;
    }
    &.fill-bg {
      main {
        > div {
          > .container {
            background-color: white;
          }
        }
      }
    }
    .badge-informative {
      background-color: #666;
      color:#fff;
    }
    .toast {
      .toast-header,
      .toast-body {
        background-color: $am-accent-color;
        color:white;
        .text-muted  {
          color:white;
        }
        .close {
          color:white;
        }
      }
    }

    .cookie-consent{
      background-color:$am-cookie-consent-color;
    }

    .loader {
      span {
        &:nth-child(1){
          background-color: #FFFFFF;
        }

        &:nth-child(2){
          background-color: $am-border-color;
        }

        &:nth-child(3){
          background-color: #FFFFFF;
        }
      }
    }
}

.afghanistan {

    #mainNav {
      #logo img {
        width:80px;
        top:0px;
      }

      &.navShrink{
        @media (min-width: $bp-tablet) {
          height: 85px;
        }
      }
    }

    header {
      &.masthead {
        background-image: url('img/dmac_amas_bg_orange.jpg');

        .intro-buttons {
          .btn-primary {
            background-color: $afghan-accent-color;

            &:hover {
              background-color: $afghan-accent-color !important;

            }
          }
          .btn-success {
            background-color: $afghan-accent-color;
            border-color: #fff;
            &:hover {
              background-color: $afghan-accent-color !important;
                border:none;
            }
          }
        }
      }
    }
    background-color:$afghan-base-color;
    & .controls{

        background-color: $afghan-dark-color;
        background-image: url('img/dmac_amas_bg_orange.jpg');

        background-size: cover;
        background-position: center;
    }
    & .item_count, .btn-primary {
        background-color: $afghan-accent-color;
    }
    & .btn-primary:hover{
        background-color: $afghan-border-color;
    }
    & .btn-primary{
        border-color: $afghan-border-color;
    }

    .btn-group {
      .btn-primary{
          border: none;
      }
    }
    h2,
    h4,

    .footer a,
    .col a ,

    label,
    .text-muted  {

    }
    .underline {
      text-decoration: underline;
    }
    &.fill-bg {
      main {
        > div {
          > .container {
            background-color: white;
          }
        }
      }
    }
    .badge-informative {
      background-color: #666;
      color:#fff;
    }
    .toast {
      .toast-header,
      .toast-body {
        background-color: $afghan-accent-color;
        color:white;
        .text-muted  {
          color:white;
        }
        .close {
          color:white;
        }
      }
    }

    .cookie-consent{
      background-color:$afghan-cookie-consent-color;
    }

    .loader {
      span {
        &:nth-child(1){
          background-color: #FFFFFF;
        }

        &:nth-child(2){
          background-color: $afghan-dark-color;
        }

        &:nth-child(3){
          background-color: #FFFFFF;
        }
      }
    }
    /*.rs-table {
      &.table-bordered {
          border-color: $afghan-dark-color;
      }
      .rs-table-row{
        border-bottom-color: $afghan-dark-color;

        .rs-table-cell {
          border-color: $afghan-dark-color;
        }
        &:nth-child(even) {
          .rs-table-cell {
            background-color: #A9A9A9 ;
          }

        }
        &:nth-child(odd) {
          .rs-table-cell {
            background-color: #808080;
          }

        }
        &:hover {
          .rs-table-cell {
            background-color: #696969;
          }
        }
      }

    }*/

}
.iddrs {

    header {
      &.masthead {
            background-color: $iddrs-dark-color;
      }
    }
    background-color:$iddrs-base-color;
    & .controls{
        background-color: $iddrs-dark-color;
        background-size: cover;
        background-position: center;
    }
    & .item_count, .btn-primary {
        background-color: $iddrs-accent-color;
    }
    & .btn-primary:hover{
        background-color: $iddrs-border-color;
    }
    & .btn-primary{
        border-color: $iddrs-border-color;
    }
    &.fill-bg {
      main {
        > div {
          > .container {
            background-color: white;
          }
        }
      }
    }
    .toast {
      .toast-header,
      .toast-body {
        background-color: $iddrs-accent-color;
        color:white;
        .text-muted  {
          color:white;
        }
        .close {
          color:white;
        }
      }
    }

    .cookie-consent{
      background-color:$iddrs-cookie-consent-color;
    }

    .loader {
      span {
        &:nth-child(1){
          background-color: #FFFFFF;
        }

        &:nth-child(2){
          background-color: $iddrs-accent-color
        }

        &:nth-child(3){
          background-color: #FFFFFF;
        }
      }
    }
}

.lbnmas {


  header {
    &.masthead {
          background-color: $lbnmas-dark-color;
    }
  }
  background-color:#fff;
    & .controls{
        background-color: $lbnmas-dark-color;
      
        background-size:cover;
        background-position: center;
    }
    & .item_count, .btn-primary {
        background-color: $lbnmas-accent-color;
    }
    & .btn-primary:hover{
        background-color: $lbnmas-border-color;
    }
    & .btn-primary{
        border-color: $lbnmas-border-color;
    }
    &.fill-bg {
      main {
        > div {
          > .container {
            background-color: white;
          }
        }
      }
    }
    .badge-informative {
      background-color: #666;
      color:#fff;
    }
    .toast {
      .toast-header,
      .toast-body {
        background-color: $lbnmas-accent-color;
        color:white;
        .text-muted  {
          color:white;
        }
        .close {
          color:white;
        }
      }
    }


    .cookie-consent{
      background-color:$lbnmas-cookie-consent-color;
    }

    .flex-table {
      &.table-row{
        &:nth-of-type(even) {
          background: #ccc;
        }
      }
    }

    .loader {
      span {
        &:nth-child(1){
          background-color: #FFFFFF;
        }

        &:nth-child(2){
          background-color: #3297FF;
        }

        &:nth-child(3){
          background-color: #FFFFFF;
        }
      }
    }
    .link-success {
        color: #198754;
    }
}
